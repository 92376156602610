@POPUP_BUTTON_BAR: ~':local(.popupButtonBar)';

@{POPUP_BUTTON_BAR} {
    margin: -20px;
    padding: 20px;
    margin-top: 0;
    overflow: auto;
    display: flex;
    justify-content: flex-end;

    > :not(:first-child) {
        margin-left: 10px;
    }
}
