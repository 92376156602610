@CHANGE_PASSWORD_TEXT_FIELD: ~':local(.changePasswordTextField)';
@CHANGE_PASSWORD_BUTTON_ICON: ~':local(.changePasswordButtonIcon)';

@icon-margin: 5px;

@{CHANGE_PASSWORD_TEXT_FIELD}:not(:first-child) {
    margin-top: 10px !important;
}

@{CHANGE_PASSWORD_BUTTON_ICON} {
    margin-right: @icon-margin !important;
}
