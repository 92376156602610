@popover: ~':local(.popover)';
@{popover} {
    .dx-popup-content {
        padding: 0;
    }
}
@userAvatar: ~':local(.userAvatar)';
@{userAvatar} {
    margin-right: 10px;
}

@USER_MENU_LIST: ~':local(.userMenuList)';
@{USER_MENU_LIST} {
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 200px;
}

@DIVIDER: ~':local(.divider)';
@{DIVIDER} {
    margin: 10px 16px;
    border: none;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}

@TOP_DIV: ~':local(.topDiv)';
@{TOP_DIV} {
    vertical-align: top;
    margin-bottom: -4px;
}

@BOTTOM_DIV: ~':local(.bottomDiv)';
@{BOTTOM_DIV} {
    vertical-align: bottom;
    margin-top: -4px;
}
