@VERSION_INFO_ROOT: ~':local(.versionInfoRoot)';
@VERSION_INFO_VERSION-NUMBER: ~':local(.versionInfoVersionNumber)';

@{VERSION_INFO_ROOT} {
    display: flex;
    color: #aaaaaa;
    font-size: xx-small;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 5px 10px;
}

@{VERSION_INFO_VERSION-NUMBER} {
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    i {
        font-size: xx-small;
        margin-right: 3px;
    }
}
