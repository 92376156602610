@ROOT: ~':local(.root)';
@MESSAGE: ~':local(.message)';

@{ROOT} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    pointer-events: none;
    z-index: 100000;
    position: fixed;
    top: 10px;
    opacity: 0.8;
}

@{MESSAGE} {
    background: #ffcc00;
    border-radius: 5px;
    padding: 7px;
    display: inline-block;
    margin-left: 10px;
}
