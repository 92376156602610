@AUTH_FORM_ROOT: ~':local(.authFormRoot)';
@AUTH_FORM_FORM: ~':local(.authFormForm)';
@AUTH_FORM_BUTTON_BAR: ~':local(.authFormButtonBar)';
@AUTH_FORM_TEXT_FIELDS: ~':local(.authFormTextFields)';
@AUTH_FORM_FIELD_PASSWORD: ~':local(.authFormFieldPassword)';
@AUTH_FORM_REGISTER_PARAGRAPH: ~':local(.authFormRegisterParagraph)';

@{AUTH_FORM_ROOT} {
    text-align: left;
    display: flex;
    flex: 1;
    justify-content: center;
}

@{AUTH_FORM_FORM} {
    max-width: 400px;
    flex: 1;
}

@{AUTH_FORM_BUTTON_BAR} {
    margin-top: 20px;
    margin-bottom: 10px;
}

@{AUTH_FORM_TEXT_FIELDS} {
    margin-top: 10px !important;
}

@{AUTH_FORM_FIELD_PASSWORD} {
    > div {
        padding-right: 0;
    }
}

@{AUTH_FORM_REGISTER_PARAGRAPH} {
    padding-top: 8px;
}
