@performa-file-manager: ~':local(.performaFileManager)';
@error-field: ~':local(.errorField)';

// Disable filemanager toolbar, breadcrumbs, headers and explorer via CSS until DevEx API allows to to so
@{performa-file-manager} {
    .dx-filemanager-toolbar,
    .dx-filemanager-breadcrumbs,
    .dx-drawer-panel-content,
    .dx-datagrid-headers {
        display: none !important;
    }
    .dx-filemanager-thumbnails-item-content,
    .dx-filemanager-thumbnails-view-port {
        padding: 0 !important;
    }
    .dx-filemanager-thumbnails-item {
        height: 85px !important;
    }

    .dx-filemanager-thumbnails-item-thumbnail {
        font-size: 48px !important;
    }

    .dx-filemanager-container {
        border: none !important;
    }

    .dx-empty-message {
        display: none;
    }

    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

@{error-field} {
    height: 0;
    transform: translate(7px, -21px) !important;
    background: transparent !important;
}
