@button: ~':local(.button)';
@buttonContent: ~':local(.buttonContent)';
@wideModeRoot: ~':local(.wideModeRoot)';
@listItemText: ~':local(.listItemText)';
@icon: ~':local(.icon)';

@{button} {
    flex-shrink: 0;
    border-radius: 0;
    height: auto;
    padding: 0;
    width: 100%;
    > .dx-button-content {
        padding: 0;
    }
}

@{buttonContent} {
    min-height: 48px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    text-align: left;
}

@{listItemText} {
    font-size: 1rem;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.75;
}

@{icon} {
    color: rgba(0, 0, 0, 0.54);
    min-width: 56px;
}
