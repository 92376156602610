@PNET_DECORATOR: ~':local(.pnetDecorator)';
@PNET_DECORATOR_CHILDREN: ~':local(.pnetDecoratorChildren)';
@PNET_DECORATOR_IMAGE_WRAPPER: ~':local(.pnetDecoratorImageWrapper)';
@PNET_DECORATOR_IMAGE: ~':local(.pnetDecoratorImage)';

@{PNET_DECORATOR} {
    display: flex;
    flex-direction: column;
}

@{PNET_DECORATOR_CHILDREN} {
    margin-top: 20px;
}

@{PNET_DECORATOR_IMAGE_WRAPPER} {
    flex: 1;
    padding-top: 30px;
}

@{PNET_DECORATOR_IMAGE} {
    width: 100%;
}
