@performa-file-uploader: ~':local(.performaFileUploader)';

@{performa-file-uploader} {
    .dx-fileuploader-content {
        display: -webkit-box;
        display: -moz-box;

        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
    }

    .dx-fileuploader-files-container {
        padding: 0 !important;
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
    }

    .dx-fileuploader-input-wrapper {
        -webkit-box-ordinal-group: 3;
        -moz-box-ordinal-group: 3;
    }

    .animate-hide-file-container {
        visibility: hidden;
        animation: fade 3000ms linear;
    }

    .remove-file-container {
        display: none !important;
    }

    .dx-fileuploader-file-status-message {
        white-space: normal !important;
    }

    .dx-fileuploader-file-status-message.upload-error {
        color: #e91e63;
    }
}

@keyframes fade {
    0% {
        opacity: 1;
        visibility: visible;
    }
    1% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
