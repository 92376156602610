@general: ~':local(.general)';
@open: ~':local(.open)';

@{general} {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    transition: all 200ms ease 0ms;
    flex-shrink: 0;

    .dx-icon {
        color: rgba(0, 0, 0, 0.54);
    }
}

@{open} {
    padding-right: 8px;
    box-sizing: border-box;
    border-right: 1px solid #e0e0e0;
}
