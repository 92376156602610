@PROFILE_FORM_ROOT: ~':local(.profileFormRoot)';
@PROFILE_FORM_FORM: ~':local(.profileFormForm)';
@PROFILE_FORM_TEXT_FIELD: ~':local(.profileFormTextField)';
@PROFILE_FORM_ACTION: ~':local(.profileFormAction)';
@PROFILE_FORM_SAVE_BUTTON: ~':local(.profileFormSaveButton)';
@PROFILE_FORM_DISCARD_BUTTON: ~':local(.profileFormDiscardButton)';

@margin: 10px;
@min-width: 64px;
@min-height: 36px;

@{PROFILE_FORM_ROOT} {
}

@{PROFILE_FORM_FORM} {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

@{PROFILE_FORM_TEXT_FIELD} {
    margin-bottom: 0 !important;
}

@{PROFILE_FORM_ACTION} {
    margin-top: @margin !important;
    display: flex;
    justify-content: flex-end;
}

@{PROFILE_FORM_DISCARD_BUTTON} {
    margin-left: @margin !important;
    min-width: @min-width;
    min-height: @min-height;
}

@{PROFILE_FORM_SAVE_BUTTON} {
    margin-left: @margin !important;
    min-width: @min-width;
    min-height: @min-height;
}
