@MARKDOWN_DETAIL_ROOT: ~':local(.markdownDetailRoot)';

@{MARKDOWN_DETAIL_ROOT} {
    border: 1px dotted grey;
    padding: 1em;
}

@MARKDOWN_DETAIL: ~':local(.markdownDetail)';
@{MARKDOWN_DETAIL} {
    .dx-field-value {
        margin-top: 0 !important;
    }
}
