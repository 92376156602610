@box: ~':local(.box)';
@invalid-message: ~':local(.invalidMessage)';

@{box} {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;

    > div {
        margin-left: 20px;
    }
}

@{invalid-message} {
    color: #e91e63;
    font-size: 0.85em;
    line-height: normal;
    word-wrap: break-word;

    > ul {
        padding-left: 15px;
        margin: 0;
    }
}
