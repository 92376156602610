@button: ~':local(.button)';
@label: ~':local(.label)';

@{button} {
    height: 32px;
    border-radius: 16px;

    > .dx-button-content {
        padding-left: 0;
        display: flex;
        align-items: center;
    }
}

@{label} {
    font-size: 0.8rem;
    margin: 8px;
}
