@button: ~':local(.button)';
@root: ~':local(.root)';
@wideModeRoot: ~':local(.wideModeRoot)';
@listItemText: ~':local(.listItemText)';
@icon: ~':local(.icon)';

@{button} {
    flex-shrink: 0;
    border-radius: 0;
    height: auto;
    padding: 0;
    > .dx-button-content {
        padding: 0;
    }
}

@{root} {
    padding: 11px 10px;
    display: flex;
    align-items: center;
    text-align: left;
}

@{wideModeRoot} {
    padding: 7px 10px;
}

@{listItemText} {
    font-size: 1rem;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.3;
}

@{icon} {
    color: rgba(0, 0, 0, 0.54);
    min-width: 56px;
}
