@root: ~':local(.root)';
@grow: ~':local(.grow)';
@appBarWrapper: ~':local(.appBarWrapper)';
@appBar: ~':local(.appBar)';
@appTitle: ~':local(.appTitle)';
@menuButton: ~':local(.menuButton)';
@logo: ~':local(.logo)';
@hide: ~':local(.hide)';
@toolbar: ~':local(.toolbar)';

@{root} {
}

@{grow} {
    flex-grow: 1;
}

@{appBarWrapper} {
    display: flex;
    position: relative;
}

@{appBar} {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    background-color: #f5f5f5;
    width: auto;
    flex-grow: 1;
    min-height: 64px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}

@{appTitle} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: inherit;
}

@{menuButton} {
    margin-left: -24px;
    margin-right: 36px;
}

@{logo} {
    margin-right: 12px;
}

@{toolbar} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
