@root: ~':local(.root)';
@bottomArea: ~':local(.bottomArea)';
@content: ~':local(.content)';
@contentWithPadding: ~':local(.contentWithPadding)';
@devexpressFixes: ~':local(.devexpressFixes)';

@{root} {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}

@{devexpressFixes} {
    .dx-datagrid-pager.dx-pager {
        border: none !important;
    }

    .dx-clear-button-area {
        align-self: baseline;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .dx-icon.dx-icon-clear {
        color: rgba(194, 194, 194, 0.87);
        background-color: transparent;
    }

    .quino-datagrid-drilldownable {
        .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
            > td:not(.dx-focused) {
            cursor: pointer;
        }
    }
}

@{bottomArea} {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

@{content} {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow: auto;
}

// these styles are important for scrolling in IE11
@{contentWithPadding} {
    padding: 24px;
    width: 100%;
    height: 100%;
    overflow: auto;
}
