@QUINO_INLINE_DATA_GRID: ~':local(.quinoInlineDataGrid)';
@EDITABLE_GRID_EDITOR: ~':local(.editableGridEditor)';
@EDITABLE_GRID_EDITOR_CREATE_BUTTON: ~':local(.editableGridEditorCreateButton)';
@EDITABLE_DETAIL_POPUP: ~':local(.editableDetailPopup)';

@{QUINO_INLINE_DATA_GRID} {
    .dx-field-value {
        margin-top: 0 !important;
    }
}

@{EDITABLE_GRID_EDITOR} {
    .dx-field-label {
        display: none;
    }
}

@{EDITABLE_GRID_EDITOR_CREATE_BUTTON} {
    margin-top: 10px;
}

@{EDITABLE_DETAIL_POPUP} {
    .dx-clear-button-area {
        align-self: baseline;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .dx-icon.dx-icon-clear {
        color: rgba(194, 194, 194, 0.87);
        background-color: transparent;
    }
}
