@scrollView: ~':local(.scrollView)';
@content: ~':local(.content)';

@popup-content-padding: 24px;

@{scrollView} {
    // The Scrollbars should be outside of the padding.
    margin: -@popup-content-padding;
    // Full height is a bit more than 100%, as we have defined a negative margin above
    height: calc(100% + 2 * @popup-content-padding);
}

@{content} {
    // Re-add the padding, as we are inside the scrollView now.
    padding: @popup-content-padding;
}
