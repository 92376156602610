@DIALOG: ~':local(.dialog)';
@{DIALOG} {
    max-height: 600px !important;
}

@CRMCONTEXTSELECTOR_THROBBERCONTAINER: ~':local(.throbberContainer)';
@{CRMCONTEXTSELECTOR_THROBBERCONTAINER} {
    min-height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}

@BUTTON_DIV: ~':local(.buttonDiv)';
@{BUTTON_DIV} {
    margin: 15px;
}

@DIALOG_CONTENT: ~':local(.dialogContent)';
@{DIALOG_CONTENT} {
    padding: 0 !important;
}

@NO_CONTEXT: ~':local(.noContext)';
@{NO_CONTEXT} {
    padding: 24px 0;
}
