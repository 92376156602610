@MARKDOWN_WRAPPER: ~':local(.markdownWrapper)';

@{MARKDOWN_WRAPPER} blockquote {
    border-left: 5px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
}

@{MARKDOWN_WRAPPER} pre {
    display: block;
    padding: 10px;
    margin: 0 0 10px;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@{MARKDOWN_WRAPPER} table {
    border-collapse: collapse;
}

@{MARKDOWN_WRAPPER} th,
@{MARKDOWN_WRAPPER} td {
    border: 1px solid #ccc;
    padding: 10px;
}
