@avatar: ~':local(.avatar)';

@{avatar} {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-color: #bdbdbd;
    color: #fafafa;

    > img {
        height: 100%;
        width: 100%;
    }
}
