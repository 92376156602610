@VERTICAL_LIST_BOX: ~':local(.verticalListBox)';

@{VERTICAL_LIST_BOX} {
    > h3 {
        margin-bottom: 0;
    }

    .dx-form-group-caption {
        margin-top: 8px;
        display: inline-block;
    }

    .dx-form-group-content {
        padding-bottom: 0;
    }
}
