@root: ~':local(.root)';
@avatar: ~':local(.avatar)';

@{root} {
    display: flex;
    align-items: center;
}

@{avatar} {
    min-width: 56px;
}
