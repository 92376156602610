/**
 * These styles allow material icons to be used together with DevExpress controls like this:
 * <Button icon="material-icons visibility" />
 * @see https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Icons/#External_Icon_Libraries
 */
.material-icons.visibility::before {
  content: 'visibility';
}
.material-icons.visibility_off::before {
  content: 'visibility_off';
}
