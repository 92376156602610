@breadcrumbs: ~':local(.breadcrumbs)';

@{breadcrumbs} {
    margin-bottom: 20px;
    font-size: smaller;

    > a {
        cursor: pointer;
        text-decoration: underline;
        color: blue;
    }
}
