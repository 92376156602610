@FORMCONTROL: ~':local(.formControl)';
@THROBBING_CONTAINER: ~':local(.throbbingContainer)';
@INPUTLABEL: ~':local(.inputLabel)';

@{FORMCONTROL} {
    margin-right: 25px;
}
@{THROBBING_CONTAINER} {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px;
}
