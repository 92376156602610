@FLOATING_VERSION_INFO_ROOT: ~':local(.floatingVersionInfoRoot)';
@FLOATING_VERSION_INFO_SPACER: ~':local(.floatingVersionInfoSpacer)';
@FLOATING_VERSION_INFO_CONTENT: ~':local(.floatingVersionInfoContent)';
@FLOATING_VERSION_INFO_CONTENT_LIGHT: ~':local(.floatingVersionInfoContentLight)';
@FLOATING_VERSION_INFO_CONTENT_DARK: ~':local(.floatingVersionInfoContentDark)';

@{FLOATING_VERSION_INFO_ROOT} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    pointer-events: none;
    z-index: 100000;
    position: fixed;
    left: 10px;
}

@{FLOATING_VERSION_INFO_CONTENT} {
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 5px 3px 0 0;
    pointer-events: auto;
}

@{FLOATING_VERSION_INFO_CONTENT_LIGHT} {
    background: rgba(255, 255, 255, 0.2);
}

@{FLOATING_VERSION_INFO_CONTENT_DARK} {
    background: rgba(0, 0, 0, 0.6);
}
